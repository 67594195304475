.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil($grid-gutter-width / 2);
    padding-right: floor($grid-gutter-width / 2);
}

.col-15 {
    flex: 0 0 20%;
    max-width: 20%;
}

/* Small Devices, Tablets */
@include media-breakpoint-up(sm) {
    .col-sm-15 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

/* Medium Devices, Desktops */
@include media-breakpoint-up(md) {
    .col-md-15 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

/* Large Devices, Wide Screens */
@include media-breakpoint-up(lg) {
    .col-lg-15 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@include media-breakpoint-up(xl) {
    .col-xl-15 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}