﻿// https://github.com/twbs/bootstrap/issues/11292
.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg, .text-left-not-xl {
    text-align: left;
}
.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg, .text-center-not-xl {
    text-align: center;
}
.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg, .text-right-not-xl {
    text-align: right;
}
.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg, .text-justify-not-xl {
    text-align: justify;
}

@include media-breakpoint-only(xs) {
    .text-left-not-xs, .text-center-not-xs, .text-right-not-xs, .text-justify-not-xs {
        text-align: inherit;
    }
    .text-left-xs {
        text-align: left;
    }
    .text-center-xs {
        text-align: center;
    }
    .text-right-xs {
        text-align: right;
    }
    .text-justify-xs {
        text-align: justify;
    }
}

@include media-breakpoint-only(sm) {
    .text-left-not-sm, .text-center-not-sm, .text-right-not-sm, .text-justify-not-sm {
        text-align: inherit;
    }
    .text-left-sm {
        text-align: left;
    }
    .text-center-sm {
        text-align: center;
    }
    .text-right-sm {
        text-align: right;
    }
    .text-justify-sm {
        text-align: justify;
    }
}

@include media-breakpoint-only(md) {
    .text-left-not-md, .text-center-not-md, .text-right-not-md, .text-justify-not-md {
        text-align: inherit;
    }
    .text-left-md {
        text-align: left;
    }
    .text-center-md {
        text-align: center;
    }
    .text-right-md {
        text-align: right;
    }
    .text-justify-md {
        text-align: justify;
    }
}

@include media-breakpoint-only(lg) {
    .text-left-not-lg, .text-center-not-lg, .text-right-not-lg, .text-justify-not-lg {
        text-align: inherit;
    }
    .text-left-lg {
        text-align: left;
    }
    .text-center-lg {
        text-align: center;
    }
    .text-right-lg {
        text-align: right;
    }
    .text-justify-lg {
        text-align: justify;
    }
}

@include media-breakpoint-only(xl) {
    .text-left-not-xl, .text-center-not-xl, .text-right-not-xl, .text-justify-not-xl {
        text-align: inherit;
    }
    .text-left-xl {
        text-align: left;
    }
    .text-center-xl {
        text-align: center;
    }
    .text-right-xl {
        text-align: right;
    }
    .text-justify-xl {
        text-align: justify;
    }
}
