﻿$grid-gutter-small-width: 5px;
$grid-gutter-large-width: 25px;

.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}

.container.no-gutter,
.container-fluid.no-gutter,
.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
}

.row.small-gutter {
    margin-left: -$grid-gutter-small-width;
    margin-right: -$grid-gutter-small-width;
}

.container.small-gutter,
.container-fluid.small-gutter,
.row.small-gutter [class*='col-']:not(:first-child),
.row.small-gutter [class*='col-']:not(:last-child) {
    padding-right: $grid-gutter-small-width;
    padding-left: $grid-gutter-small-width;
}

.row.large-gutter {
    margin-left: -$grid-gutter-large-width;
    margin-right: -$grid-gutter-large-width;
}

.container.large-gutter,
.container-fluid.large-gutter,
.row.large-gutter [class*='col-']:not(:first-child),
.row.large-gutter [class*='col-']:not(:last-child) {
    padding-right: $grid-gutter-large-width;
    padding-left: $grid-gutter-large-width;
}

