﻿.border-all-gray-lighter {
  border: 1px solid $gray-lighter; 
}

.border-top-gray-lighter {
  border-top: 1px solid $gray-lighter; 
}

.border-bottom-gray-lighter {
  border-bottom: 1px solid $gray-lighter; 
}

.border-right-gray-lighter {
  border-right: 1px solid $gray-lighter;
}

.border-left-gray-lighter {
  border-left: 1px solid $gray-lighter; 
}

.border-all-gray-light {
  border: 1px solid $gray-light; 
}

.border-top-gray-light {
  border-top: 1px solid $gray-light; 
}

.border-bottom-gray-light {
  border-bottom: 1px solid $gray-light; 
}

.border-right-gray-light {
  border-right: 1px solid $gray-light;
}

.border-left-gray-light {
  border-left: 1px solid $gray-light; 
}

.border-all-gray-dark {
  border: 1px solid $gray-dark; 
}

.border-top-gray-dark {
  border-top: 1px solid $gray-dark; 
}

.border-bottom-gray-dark {
  border-bottom: 1px solid $gray-dark; 
}

.border-right-gray-dark {
  border-right: 1px solid $gray-dark; 
}

.border-left-gray-dark {
  border-left: 1px solid $gray-dark; 
}


.radius 
{
  border-radius: $border-radius; 
}

.radius-top 
{
  border-radius: $border-radius $border-radius 0 0; 
}

.radius-right 
{
  border-radius: 0 $border-radius $border-radius 0; 
}

.radius-bottom 
{
  border-radius: 0 0 $border-radius $border-radius; 
}

.radius-left 
{
  border-radius: $border-radius 0 0 $border-radius; 
}

.radius-large 
{
  border-radius: $border-radius-lg; 
}

.radius-large-top 
{
  border-radius: $border-radius-lg $border-radius-lg 0 0; 
}

.radius-large-right 
{
  border-radius: 0 $border-radius-lg $border-radius-lg 0; 
}

.radius-large-bottom 
{
  border-radius: 0 0 $border-radius-lg $border-radius-lg; 
}

.radius-large-left 
{
  border-radius: $border-radius-lg 0 0 $border-radius-lg; 
}

.radius-small 
{
  border-radius: $border-radius-sm; 
}

.radius-small-top 
{
  border-radius: $border-radius-sm $border-radius-sm 0 0; 
}

.radius-small-right 
{
  border-radius: 0 $border-radius-sm $border-radius-sm 0; 
}

.radius-small-bottom 
{
  border-radius: 0 0 $border-radius-sm $border-radius-sm; 
}

.radius-small-left 
{
  border-radius: $border-radius-sm 0 0 $border-radius-sm; 
}  
