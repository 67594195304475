﻿// http://stackoverflow.com/a/29094312/1479311

.float-right-not-xs, .float-right-not-sm, .float-right-not-md, .float-right-not-lg, .float-right-not-xl {
    float: right!important;
}

.float-left-not-xs, .float-left-not-sm, .float-left-not-md, .float-left-not-lg, .float-left-not-xl {
    float: left!important;
}

@include media-breakpoint-only(xs) {
    .float-right-not-xs, .float-left-not-xs{
        float: none!important;
    }
    .float-right-xs {
        float: right!important;
    }
    .float-left-xs {
        float: left!important;
    }
}

@include media-breakpoint-only(sm) {
    .float-right-not-sm, .float-left-not-sm{
        float: none!important;
    }
    .float-right-sm {
        float: right!important;
    }
    .float-left-sm {
        float: left!important;
    }
}

@include media-breakpoint-only(md) {
    .float-right-not-md, .float-left-not-md{
        float: none!important;
    }
    .float-right-md {
        float: right!important;
    }
    .float-left-md {
        float: left!important;
    }
}

@include media-breakpoint-only(lg) {
    .float-right-not-lg, .float-left-not-lg{
        float: none!important;
    }
    .float-right-lg {
        float: right!important;
    }
    .float-left-lg {
        float: left!important;
    }
}

@include media-breakpoint-only(xl) {
    .float-right-not-xl, .float-left-not-xl{
        float: none!important;
    }
    .float-right-xl {
        float: right!important;
    }
    .float-left-xl {
        float: left!important;
    }
}