﻿.box-xs-quarter-height
{
    &:before {
        content: "";
        display: block;
        padding-top: 25%;
    }
}

.box-xs-onethird-height
{
    &:before {
        content: "";
        display: block;
        padding-top: 33.333333333%;
    }
}

.box-xs-half-height
{
    &:before {
        content: "";
        display: block;
        padding-top: 50%;
    }
}

.box-xs-square
{
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}

.box-xs-two-height
{
    &:before {
        content: "";
        display: block;
        padding-top: 200%;
    }
}

.box-xs-three-height
{
    &:before {
        content: "";
        display: block;
        padding-top: 300%;
    }
}

.box-xs-four-height
{
    &:before {
        content: "";
        display: block;
        padding-top: 400%;
    }
}

.box-xs-16x9
{
    &:before {
        content: "";
        display: block;
        padding-top: percentage(9 / 16);
    }
}

.box-xs-4x3
{
    &:before {
        content: "";
        display: block;
        padding-top: percentage(3 / 4);
    }
}

.box-xs-quarter-height,
.box-xs-onethird-height,
.box-xs-half-height,
.box-xs-square,
.box-xs-two-height,
.box-xs-three-height,
.box-xs-four-height,
.box-xs-16x9,
.box-xs-4x3
{
	> div {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
        overflow: hidden;
	}
}

@include media-breakpoint-up(sm) {
    .box-sm-quarter-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 25%;
        }
    }

    .box-sm-onethird-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 33.333333333%;
        }
    }

    .box-sm-half-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 50%;
        }
    }

    .box-sm-square
    {
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .box-sm-two-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 200%;
        }
    }

    .box-sm-three-height
    {
        padding-left: 0;
        padding-right: 0;
        &:before {
            content: "";
            display: block;
            padding-top: 300%;
        }
    }

    .box-sm-four-height
    {
        padding-left: 0;
        padding-right: 0;
        &:before {
            content: "";
            display: block;
            padding-top: 400%;
        }
    }

    .box-sm-16x9
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(9 / 16);
        }
    }

    .box-sm-4x3
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(3 / 4);
        }
    }

    .box-sm-quarter-height,
    .box-sm-onethird-height,
    .box-sm-half-height,
    .box-sm-square,
    .box-sm-two-height,
    .box-sm-three-height,
    .box-sm-four-height,
    .box-sm-16x9,
    .box-sm-4x3
    {
	    > div {
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
            overflow: hidden;
	    }
    }
}

@include media-breakpoint-up(md) {
    .box-md-quarter-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 25%;
        }
    }

    .box-md-onethird-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 33.333333333%;
        }
    }

    .box-md-half-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 50%;
        }
    }

    .box-md-square
    {
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .box-md-two-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 200%;
        }
    }

    .box-md-three-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 300%;
        }
    }

    .box-md-four-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 400%;
        }
    }

    .box-md-16x9
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(9 / 16);
        }
    }

    .box-md-4x3
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(3 / 4);
        }
    }

    .box-md-quarter-height,
    .box-md-onethird-height,
    .box-md-half-height,
    .box-md-square,
    .box-md-two-height,
    .box-md-three-height,
    .box-md-four-height,
    .box-md-16x9,
    .box-md-4x3
    {
	    > div {
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
            overflow: hidden;
	    }
    }
}

@include media-breakpoint-up(lg) {
    .box-lg-quarter-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 25%;
        }
    }

    .box-lg-onethird-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 33.333333333%;
        }
    }

    .box-lg-half-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 50%;
        }
    }

    .box-lg-square
    {
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .box-lg-two-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 200%;
        }
    }

    .box-lg-three-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 300%;
        }
    }

    .box-lg-four-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 400%;
        }
    }

    .box-lg-16x9
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(9 / 16);
        }
    }

    .box-lg-4x3
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(3 / 4);
        }
    }

    .box-lg-quarter-height,
    .box-lg-onethird-height,
    .box-lg-half-height,
    .box-lg-square,
    .box-lg-two-height,
    .box-lg-three-height,
    .box-lg-four-height,
    .box-lg-16x9,
    .box-lg-4x3
    {
	    > div {
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
            overflow: hidden;
	    }
    }
}

@include media-breakpoint-up(xl) {
    .box-xl-quarter-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 25%;
        }
    }

    .box-xl-onethird-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 33.333333333%;
        }
    }

    .box-xl-half-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 50%;
        }
    }

    .box-xl-square
    {
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .box-xl-two-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 200%;
        }
    }

    .box-xl-three-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 300%;
        }
    }

    .box-xl-four-height
    {
        &:before {
            content: "";
            display: block;
            padding-top: 400%;
        }
    }

    .box-xl-16x9
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(9 / 16);
        }
    }

    .box-xl-4x3
    {
        &:before {
            content: "";
            display: block;
            padding-top: percentage(3 / 4);
        }
    }

    .box-xl-quarter-height,
    .box-xl-onethird-height,
    .box-xl-half-height,
    .box-xl-square,
    .box-xl-two-height,
    .box-xl-three-height,
    .box-xl-four-height,
    .box-xl-16x9,
    .box-xl-4x3
    {
	    > div {
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
            overflow: hidden;
	    }
    }
}