$spacers: (
  none: (
    x: 0,
    y: 0
  ),
  quarter: (
    x: ($spacer / 4),
    y: ($spacer / 4)
  ),
  half: (
    x: ($spacer / 2),
    y: ($spacer / 2)
  ),
  one: (
    x: $spacer,
    y: $spacer
  ),
  two: (
    x: ($spacer * 2),
    y: ($spacer * 2)
  ),
  four: (
    x: ($spacer * 4),
    y: ($spacer * 4)
  )
);

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: margin, padding: padding) {
      @each $size, $lengths in $spacers {
        $length-x:   map-get($lengths, x);
        $length-y:   map-get($lengths, y);

        .#{$abbrev}#{$infix}-#{$size} { #{$prop}:        $length-y $length-x !important; } // a = All sides
        .#{$abbrev}-top#{$infix}-#{$size} { #{$prop}-top:    $length-y !important; }
        .#{$abbrev}-right#{$infix}-#{$size} { #{$prop}-right:  $length-x !important; }
        .#{$abbrev}-bottom#{$infix}-#{$size} { #{$prop}-bottom: $length-y !important; }
        .#{$abbrev}-left#{$infix}-#{$size} { #{$prop}-left:   $length-x !important; }

        // Axes
        .#{$abbrev}-x#{$infix}-#{$size} {
          #{$prop}-right:  $length-x !important;
          #{$prop}-left:   $length-x !important;
        }
        .#{$abbrev}-y#{$infix}-#{$size} {
          #{$prop}-top:    $length-y !important;
          #{$prop}-bottom: $length-y !important;
        }
      }
    }
  }
}
