﻿// from http://www.bootply.com/7UhIWMK808
// .row-flex (make columns equal heights in each row)

.container-flex,
.container-flex > .row > div[class*='col-'],
.row-flex, 
.row-flex > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex: 1 1 none;
    -webkit-flex: 1 1 none;
    flex: 1 1 none;
}

.container-flex {
    -ms-flex-flow: column nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
}

.container-flex > .row > div[class*='col-'],
.row-flex > div[class*='col-']
 {
	margin: -.2px; /* hack adjust for wrapping */
}

.container-flex > .row > div[class*='col-'] > div, 
.row-flex > div[class*='col-'] > div {
	width: 100%;
}

.row-flex-wrap {
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-align-content: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    -ms-flex: 0;
    -webkit-flex: 0;
    flex: 0;
}

.flex-col {
    display: flex;
    display: -webkit-flex;
    -ms-flex: 1 100%;
    -webkit-flex: 1 100%;
    flex: 1 100%;
    -ms-flex-flow: column nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
}

.flex-grow {
    display: flex;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    -ms-flex-flow: column nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
}

